/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { Amount } from '../models/Amount';
import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { Paginated_AppWalletTrxV4_ } from '../models/Paginated_AppWalletTrxV4_';
import type { PgWalletTrx } from '../models/PgWalletTrx';
import type { QuerySpec } from '../models/QuerySpec';
import type { TopupDirectDebitResponseV3 } from '../models/TopupDirectDebitResponseV3';
import type { UpdateMerchantWallet } from '../models/UpdateMerchantWallet';
import type { WalletLimits } from '../models/WalletLimits';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class MerchantWalletsService {

    constructor(private readonly http: HttpClient) {}

    /**
     * Get the list of transaction for the wallet associated with the integration profile.
     * @param merchantId
     * @param integrationProfileId
     * @param last
     * @param limit
     * @returns Paginated_AppWalletTrxV4_ A list of wallet transactions for the merchant profile.
     * @throws ApiError
     */
    public listWalletTrxs(
        merchantId: string,
        integrationProfileId: string,
        last?: EncryptedLastItem,
        limit?: number,
    ): Observable<Paginated_AppWalletTrxV4_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/merchants/{merchantId}/{integrationProfileId}/wallet',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * Search the list of transaction for the wallet associated with the integration profile.
     * @param merchantId
     * @param integrationProfileId
     * @param requestBody
     * @param offset
     * @param limit
     * @returns PgWalletTrx A list of wallet transactions for the merchant profile.
     * @throws ApiError
     */
    public searchWalletTrxs(
        merchantId: string,
        integrationProfileId: string,
        requestBody: QuerySpec,
        offset?: number,
        limit: number = 20,
    ): Observable<Array<PgWalletTrx>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/merchants/{merchantId}/{integrationProfileId}/wallet',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the wallet details for the merchant wallet.
     * @param merchantId
     * @param integrationProfileId
     * @returns any Details about the merchant wallet.
     * @throws ApiError
     */
    public getWalletDetails(
        merchantId: string,
        integrationProfileId: string,
    ): Observable<{
        limits: WalletLimits;
        balance: Amount;
        topups: TopupDirectDebitResponseV3;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/merchants/{merchantId}/{integrationProfileId}/wallet/details',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
        });
    }

    /**
     * Update the wallet details for the merchant wallet.
     * @param merchantId
     * @param integrationProfileId
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public updateWallet(
        merchantId: string,
        integrationProfileId: string,
        requestBody: UpdateMerchantWallet,
    ): Observable<{
        limits: WalletLimits;
        balance: Amount;
        topups: TopupDirectDebitResponseV3;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/v1/merchants/{merchantId}/{integrationProfileId}/wallet/details',
            path: {
                'merchantId': merchantId,
                'integrationProfileId': integrationProfileId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}