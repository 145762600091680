import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AppWalletTrxV4 } from 'app/api/generated';

export interface WalletsTransactionsState extends EntityState<AppWalletTrxV4, string> {
  selected: AppWalletTrxV4;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'wallets-transactions', idKey: 'trxId' })
export class WalletsTransactionsStore extends EntityStore<WalletsTransactionsState, AppWalletTrxV4, string> {
  constructor() {
    super();
  }
}
