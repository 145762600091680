import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import {
  BankAccountConfigService,
  ConsentDefinitionService,
  KycClearService,
  KycConfigOverrideService,
  KycConfigService,
  KycPhoneWhitelistService,
  EmailTemplateService,
  LoginService,
  MerchantOnboardingsService,
  MerchantTermsAndConditionsService,
  MfawhitelistService,
  ParametersService,
  SmsTemplateService,
  TermsAndConditionsService,
  UsersService,
  AppusersService,
  BtwhitelistService,
  MerchantsService,
  BlacklistService,
  ManualOnboardingService,
  TranslationsService,
  IntegrationProfilesService,
  SqsErrorHandlingService,
  InvoicingService,
  SettlementsService,
  BankAccountLedgerEntriesService,
  BankTransferOrdersService,
  TopudirectdebitconfigService,
  MerchantWalletsService,
  MerchantListService,
  ReportingService,
  ItcardService,
  OrderListService,
  LocationsService,
  MerchantOrdersService,
  SanctionListService,
  KycTestCasesService,
  DefaultService,
  AppuserskycService,
  OrderFailuresService,
  OrderService,
} from 'app/api/generated';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { OpenAppModule } from '@openapp';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from 'environments/environment';
import { GenericLayerService } from './api/generated/services/GenericLayerService';
import { PurchasesService } from './modules/purchases/data-access/purchases.service';
import { WalletTransactionsService } from './modules/wallets/data-access/wallets-transactions/wallets-transations.service';
import { HttpClientModule } from '@angular/common/http';

const developmentImports = [environment.production ? [] : AkitaNgDevtools.forRoot()];

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  declarations: [AppComponent, ForbiddenComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,

    // 3rd party modules that require global configuration via forRoot
    OpenAppModule,
    ...developmentImports,
    HttpClientModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    LoginService,
    MfawhitelistService,
    ParametersService,
    MfawhitelistService,
    UsersService,
    TermsAndConditionsService,
    MerchantTermsAndConditionsService,
    MerchantOnboardingsService,
    BankAccountConfigService,
    ConsentDefinitionService,
    GenericLayerService,
    KycConfigService,
    KycConfigOverrideService,
    KycPhoneWhitelistService,
    KycClearService,
    EmailTemplateService,
    SmsTemplateService,
    AppusersService,
    BtwhitelistService,
    MerchantsService,
    PurchasesService,
    WalletTransactionsService,
    BlacklistService,
    ManualOnboardingService,
    TranslationsService,
    IntegrationProfilesService,
    SqsErrorHandlingService,
    InvoicingService,
    SettlementsService,
    BankAccountLedgerEntriesService,
    BankTransferOrdersService,
    TopudirectdebitconfigService,
    MerchantWalletsService,
    MerchantListService,
    ReportingService,
    ItcardService,
    OrderListService,
    LocationsService,
    MerchantOrdersService,
    SanctionListService,
    MerchantWalletsService,
    KycTestCasesService,
    DefaultService,
    AppuserskycService,
    OrderFailuresService,
    OrderService,
  ],
})
export class AppModule {}
