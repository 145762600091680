import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'appusers' },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'appusers' },
  { path: 'forbidden', component: ForbiddenComponent },
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import('app/modules/auth/confirmation-required/confirmation-required.module').then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then((m) => m.AuthResetPasswordModule),
      },
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
      },
      {
        path: 'sign-up',
        loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule),
      },
      {
        path: 'login',
        loadChildren: () => import('app/modules/auth/oauth-redirect/oauth-redirect.module').then((m) => m.OAuthRedirectModule),
      },
      {
        path: 'user-activation',
        loadChildren: () => import('app/modules/user-activation/user-activation.module').then((m) => m.UserActivationModule),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
      },
      // {path: 'unlock-session', loadChildren: () =>
      // import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'home',
        loadChildren: () => import('app/modules/landing/home/home.module').then((m) => m.LandingHomeModule),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'merchants',
        loadChildren: () => import('app/modules/merchants/merchants.module').then((m) => m.MerchantsModule),
      },
      {
        path: 'deliveryProviders',
        loadChildren: () => import('app/modules/delivery-providers/delivery-providers.module').then((m) => m.DeliveryProvidersModule),
      },
      {
        path: 'fakeBaskets',
        loadChildren: () => import('app/modules/fake-baskets/fake-baskets.module').then((m) => m.FakeBasketsModule),
      },
      {
        path: 'fakeBills',
        loadChildren: () => import('app/modules/fake-bills/fake-bills.module').then((m) => m.FakeBillsModule),
      },
      {
        path: 'mfaWhitelist',
        loadChildren: () => import('app/modules/mfa-whitelist/mfa-whitelist.module').then((m) => m.MfaWhitelistModule),
      },
      {
        path: 'parameters',
        loadChildren: () => import('app/modules/parameters/parameters.module').then((m) => m.ParametersModule),
      },
      {
        path: 'users',
        data: { role: 'MANAGE_USERS' },
        canLoad: [AuthGuard],
        loadChildren: () => import('app/modules/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'appusers',
        loadChildren: () => import('app/modules/appusers/appusers.module').then((m) => m.AppusersModule),
      },
      {
        path: 'appusers-blacklist',
        loadChildren: () => import('app/modules/appusers-blacklist/appusers-blacklist.module').then((m) => m.AppusersBlacklistModule),
      },
      {
        path: 'documents',
        loadChildren: () => import('app/modules/documents/documents.module').then((m) => m.DocumentsModule),
      },
      {
        path: 'orders',
        loadChildren: () => import('app/modules/orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: 'orders-failures',
        loadChildren: () => import('app/modules/orders-failures/orders-failures.module').then((m) => m.OrdersFailuresModule),
      },
      // {
      //   path: 'purchases',
      //   loadChildren: () => import('app/modules/purchases/purchases.module').then((m) => m.PurchasesModule),
      // },
      {
        path: 'onboardings',
        loadChildren: () =>
          import('app/modules/onboarding-management/onboarding-management.module').then((m) => m.OnboardingManagementModule),
      },
      {
        path: 'sanctionList',
        loadChildren: () => import('app/modules/sanction-list/sanction-list.module').then((m) => m.SanctionListModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('app/modules/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'logs',
        loadChildren: () => import('app/modules/logs/logs.module').then((m) => m.LogsModule),
      },
      {
        path: 'analytics',
        loadChildren: () => import('app/modules/analytics/analytics.module').then((m) => m.AnalyticsModule),
      },
      {
        path: 'templates',
        loadChildren: () => import('app/modules/templates/templates.module').then((m) => m.TemplatesModule),
      },
      {
        path: 'bankTransfersOrders',
        loadChildren: () => import('app/modules/bank-transfer-orders/bank-transfer-orders.module').then((m) => m.BankTransferOrdersModule),
      },
      {
        path: 'bankTopup',
        loadChildren: () => import('app/modules/bank-topup/bank-topup.module').then((m) => m.BankTopupModule),
      },
      {
        path: 'bankAccounts',
        data: { role: 'BANK_ACCOUNT_CONFIG' },
        loadChildren: () => import('app/modules/bank-config/bank-config.module').then((m) => m.BankConfigModule),
      },
      {
        path: 'bankLedger',
        loadChildren: () => import('app/modules/bank-ledger/bank-ledger.module').then((m) => m.BankLedgerModule),
      },
      {
        path: 'applePayTest',
        loadChildren: () => import('app/modules/apple-pay-test/apple-pay-test.module').then((m) => m.ApplePayTestModule),
      },
      {
        path: 'kyc',
        loadChildren: () => import('app/modules/kyc/kyc.module').then((m) => m.KycModule),
      },
      {
        path: 'translations',
        loadChildren: () => import('app/modules/translations/translations.module').then((m) => m.TranslationsModule),
      },
      {
        path: 'sqs-error',
        loadChildren: () => import('app/modules/sqs-error/sqs-error.module').then((m) => m.SqsErrorModule),
      },
      {
        path: 'btwhitelist',
        loadChildren: () => import('app/modules/bt-whitelist/bt-whitelist.module').then((m) => m.BtWhitelistModule),
      },
      {
        path: 'invoicing',
        loadChildren: () => import('app/modules/invoicing/invoicing.module').then((m) => m.InvoicingModule),
      },
      {
        path: 'settlements',
        loadChildren: () => import('app/modules/settlements/settlements.module').then((m) => m.SettlementsModule),
      },
      {
        path: 'psp-transaction-log',
        loadChildren: () => import('app/modules/psp-transaction-log/psp-transaction-log.module').then((m) => m.PspTransactionLogModule),
      },
      {
        path: 'kyc-test-cases',
        loadChildren: () => import('app/modules/kyc-test-cases/kyc-test-cases.module').then((m) => m.KycTestCasesModule),
      },
      {
        path: 'topups',
        loadChildren: () => import('app/modules/topups/topups.module').then((m) => m.TopupsModule),
      },
    ],
  },
];
