/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EmailTemplate } from '../models/EmailTemplate';
import type { EmailTemplateData } from '../models/EmailTemplateData';
import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { Omit_EmailTemplate_id_or_locale_or_type_ } from '../models/Omit_EmailTemplate_id_or_locale_or_type_';
import type { Paginated_EmailTemplate_ } from '../models/Paginated_EmailTemplate_';
import type { SupportedLanguage } from '../models/SupportedLanguage';
import type { TemplateCategory } from '../models/TemplateCategory';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class EmailTemplateService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param id
     * @param locale
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public create(
        id: string,
        locale: SupportedLanguage,
        requestBody: Omit_EmailTemplate_id_or_locale_or_type_,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/template/email/{id}/{locale}',
            path: {
                'id': id,
                'locale': locale,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param locale
     * @returns EmailTemplate Ok
     * @throws ApiError
     */
    public get(
        id: string,
        locale: SupportedLanguage,
    ): Observable<EmailTemplate> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/template/email/{id}/{locale}',
            path: {
                'id': id,
                'locale': locale,
            },
        });
    }

    /**
     * @param id
     * @param locale
     * @returns void
     * @throws ApiError
     */
    public remove(
        id: string,
        locale: SupportedLanguage,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/v1/template/email/{id}/{locale}',
            path: {
                'id': id,
                'locale': locale,
            },
        });
    }

    /**
     * @param id
     * @param locale
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public update(
        id: string,
        locale: SupportedLanguage,
        requestBody: Omit_EmailTemplate_id_or_locale_or_type_,
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/v1/template/email/{id}/{locale}',
            path: {
                'id': id,
                'locale': locale,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param encryptedLastItem
     * @returns Paginated_EmailTemplate_ Ok
     * @throws ApiError
     */
    public list(
        encryptedLastItem?: EncryptedLastItem,
    ): Observable<Paginated_EmailTemplate_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/template/email',
            headers: {
                'encryptedLastItem': encryptedLastItem,
            },
        });
    }

    /**
     * @param type
     * @returns EmailTemplateData Ok
     * @throws ApiError
     */
    public listV2(
        type?: TemplateCategory,
    ): Observable<Array<EmailTemplateData>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v2/template/email',
            query: {
                'type': type,
            },
        });
    }

}